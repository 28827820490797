<template>
    <div>
        <h1 class="page-heading" v-html="resource[lang].heading" />
        <div class="copyright flex">
            <div class="heading">
                <h2 class="authentication-heading" v-html="resource[lang].copyright.heading" />
            </div>
            <hr noshade>
            <div class="content">
                <p class="authentication-text" v-html="resource[lang].copyright.text" />
            </div>
        </div>

        <div class="liability flex">
            <div class="heading">
                <h2 class="authentication-heading" v-html="resource[lang].liability.heading" />
            </div>
            <hr noshade>
            <div class="content">
                <p class="authentication-text" v-html="resource[lang].liability.text" />
            </div>
        </div>

        <div class="content flex">
            <div class="heading">
                <h2 class="authentication-heading" v-html="resource[lang].content.heading" />
            </div>
            <hr noshade>
            <div class="content">
                <p class="authentication-text" v-html="resource[lang].content.phone" />
                <p class="authentication-text" v-html="resource[lang].content.mail" />
                <p class="authentication-text" v-html="resource[lang].content.mail_2" />
            </div>
        </div>
    </div>
</template>

<script>
import resource from '@/assets/resources/legal-information-resource'

export default {
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            resource
        }
    },
}
</script>

<style lang="scss" scoped>

hr {
    display: none;
}

h1 {
    margin-block-end: 5rem;
}

.flex {
    flex-wrap: nowrap;
    align-items: center;

    .heading h2 {
        text-align: right;
        margin: 0;
    }

    .content p {
        text-align: left;
        max-width: 75ch;
    }

    .content {
        flex-basis: 65%;
        padding-block: 2rem;
        margin-block: 2rem;
        border-left: solid 5px var(--dark_grey);
    }

    .heading {
        flex-basis: 35%;
    }

    & > * {
        padding-inline: 2rem;
    }
    
    
}

@media (max-width: 62rem) {
    h1 {
        text-align: center;
    }

    hr {
        display: block;

        max-width: 2rem;

        margin: auto;

        border-bottom: solid 5px var(--dark_grey);
    }


    .flex {
        display: block;

        .heading {
            margin-bottom: 1rem;
        }

        .heading h2 {
            text-align: center;
            margin: 0;

            margin: auto;
        }

        .content {
            border: none;

            margin-top: 0;
        }

        .content p {
            text-align: center;
            max-width: 75ch;
            margin: auto;
        }
    }
}

</style>