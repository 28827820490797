const resource = {
    en: {
        heading: 'Legal Information',
        copyright: {
            heading: 'Copyright',
            text: '© The contents of the website are published in the World Wide Web for online access. Copyright for texts, images, graphic design and source code is owned by the University of Vienna and subject to legal protection. The production, use and non-commercial distribution of copies in electronic or printed form is allowed provided that the contents are not altered and the source is mentioned (Source: University of Vienna).'
        },
        liability: {
            heading: 'Liability',
            text: 'The text provided on the University of Vienna homepage has been reviewed with great care. However, the University of Vienna cannot guarantee the accuracy, completeness or validity of the information provided. Therefore the University of Vienna accepts no liability for the contents provided. Links to other websites have been carefully selected. However, the University of Vienna is not responsible for contents on any other websites.'
        },
        content: {
            heading: 'Responsibility for contents and editing',
            phone: '+43-1-4277-40013',
            mail: 'bettina.paireder@univie.ac.at',
            mail_2: 'alois.ecker@uni-graz.at'
        }
    },
    de: {
        heading: 'Impressum',
        copyright: {
            heading: 'Copyright',
            text: '© Die Inhalte der Website sind im World-Wide-Web für den Online-Zugriff veröffentlicht, das Urheberrecht und die Nutzungsrechte (Copyright) für Texte, Graphiken, Design und Quellcode liegen bei der Universität Wien, ebenso das Nutzungsrecht für die Bilder. Die Erstellung, die Verwendung und die nicht kommerzielle Weitergabe von Kopien in elektronischer oder ausgedruckter Form sind erlaubt, wenn der Inhalt unverändert bleibt und die Quelle angegeben wird (Quelle: Universität Wien).'
        },
        liability: {
            heading: 'Haftung',
            text: 'Sämtliche Texte auf der Homepage der Universität Wien wurden sorgfältig geprüft. Dessen ungeachtet kann keine Garantie für die Richtigkeit, Vollständigkeit und Aktualität der Angaben übernommen werden. Eine Haftung der Universität Wien wird daher ausgeschlossen. Die Links zu anderen Webseiten wurden sorgfältig ausgewählt. Da die Universität Wien auf deren Inhalt keinen Einfluss hat, übernimmt die Universität Wien dafür keine Verantwortung.'
        },
        content: {
            heading: 'Inhaltliche und redaktionelle Verantwortung',
            phone: '+43-1-4277-40013',
            mail: 'bettina.paireder@univie.ac.at',
            mail_2: 'alois.ecker@uni-graz.at'
        }
    }
}

export default resource;